<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template slot="extraSubtitle">
          <template
            v-if="formattedItem.tiempo_trabajado"
          >
            <v-chip
              v-if="formattedItem.tiempo_trabajado.es_desplazamiento"
              label
              small
              color="brown"
              dark
              class="mt-1 ml-1"
            >
              Es desplazamiento
            </v-chip>
            <v-chip
              v-else
              label
              small
              color="blue"
              dark
              class="mt-1 ml-1"
            >
              Es mano de obra
            </v-chip>
            <v-chip
              v-if="formattedItem.tiempo_trabajado.manodeobrafacturable"
              label
              small
              color="green"
              dark
              class="mt-1 ml-1"
            >
              M.O. facturable
            </v-chip>
            <v-chip
              v-else
              label
              small
              color="red"
              dark
              class="mt-1 ml-1"
            >
              M.O. no facturable
            </v-chip>
            <v-chip
              v-if="formattedItem.tiempo_trabajado.kilometros_facturables"
              label
              small
              color="green"
              dark
              class="mt-1 ml-1"
            >
              Desplazamiento facturable
            </v-chip>
            <v-chip
              v-else
              label
              small
              color="red"
              dark
              class="mt-1 ml-1"
            >
              Desplazamiento no facturable
            </v-chip>
          </template>
        </template>
      </b10-view-summary>
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './TiempoTrabajadoViewData'
import { nonEmpty } from '@/utils/templates'
import { PARTE_TRABAJO } from '@/utils/consts'
import { getLatLongURL } from '@/utils/maps'

export default {
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'finicio', label: 'Inicio' },
        { name: 'ffin', label: 'Fin' },
        { name: 'empleado.nombre_y_apellido', label: 'Empleado' },
        { name: 'tiempoTrabajadoDesc', label: 'Trabajado' },
        { name: 'tiempo_trabajado.es_desplazamiento', label: 'Es desplazamiento', filter: this.$options.filters.humanizeBoolean },
        { name: 'tiempo_trabajado.manodeobrafacturble', label: 'M.O. facturable', filter: this.$options.filters.humanizeBoolean },
        { name: 'tiempo_trabajado.kilometros_facturables', label: 'Desplazamiento facturable', filter: this.$options.filters.humanizeBoolean },
        { name: 'ttarea.descripcion', label: 'Tipo de tarea' },
        { name: 'tiempo_trabajado.observaciones', label: 'Observaciones' },
        { name: 'vehiculo.descripcion', label: 'Vehículo' },
      ],
      toolbarOptions: {
        delete: {
          title: 'Eliminar',
          visible: true,
          icon: 'delete'
        },
        mapInicio: {
          title: 'Ver inicio en el mapa',
          visible: true,
          icon: 'map',
        },
        mapFin: {
          title: 'Ver fin en el mapa',
          visible: true,
          icon: 'map',
        },
      },
      showingDialogs: {
        moreInfo: false,
      },
    }
  },
  computed: {
    estadoPartePendiente () {
      return (
        this.item.dataset &&
        this.item.dataset.parte_trabajo &&
        this.item.dataset.parte_trabajo.estado === PARTE_TRABAJO.estados.pendiente
      )
    },
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.tiempo_trabajado.idtiempo_trabajado
        item.title = this.$options.filters.linebreaksBr(nonEmpty`${item.empleado.codigo} - ${item.empleado.nombre_y_apellido}`)
        item.tiempoTrabajado = item.tiempo_trabajado.ffin.getTime() - item.tiempo_trabajado.finicio.getTime()
        item.tiempoTrabajadoDesc = this.$options.filters.humanizeDuration(item.tiempoTrabajado)
        item.finicio = this.$options.filters.shortDateTime(item.tiempo_trabajado.finicio)
        item.ffin = this.$options.filters.shortDateTime(item.tiempo_trabajado.ffin)
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.finicio} - Trabajado ${item.tiempoTrabajadoDesc}
          ${item.subsis.descripcion}
        `)
        item.moreInfo = this.buildMoreInfo()
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = 'Tiempo trabajado'
      this.toolbarOptions.delete.visible = this.estadoPartePendiente && this.hasDeletePerm(this.permissions.parteTrabajoTiempoTrabajado.id)
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectTiempoTrabajado(this, this.routeParams.idtiempo_trabajado)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.toolbarOptions.mapInicio.visible = (
            !!this.item.dataset.tiempo_trabajado.latitud_inicio &&
            !!this.item.dataset.tiempo_trabajado.longitud_inicio
          )
          this.toolbarOptions.mapFin.visible = (
            !!this.item.dataset.tiempo_trabajado.latitud_fin &&
            !!this.item.dataset.tiempo_trabajado.longitud_fin
          )
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.delete) {
        const res = await this.$alert.showConfirm(
          '¿Deseas eliminar el tiempo trabajado del parte de trabajo?'
        )
        if (res) {
          await this.$offline.tiempoTrabajado.deleteSync(this.routeParams.idtiempo_trabajado)
          await this.$dirty.deleted(this.$dirty.ENTITIES.local.tiempoTrabajado, this.routeParams.idtiempo_trabajado)
          this.$appRouter.go(-1)
        }
      } else if (option === this.toolbarOptions.mapInicio) {
        window.open(
          getLatLongURL(
            this.item.dataset.tiempo_trabajado.latitud_inicio,
            this.item.dataset.tiempo_trabajado.longitud_inicio
          ),
          '_blank'
        )
      } else if (option === this.toolbarOptions.mapFin) {
        window.open(
          getLatLongURL(
            this.item.dataset.tiempo_trabajado.latitud_fin,
            this.item.dataset.tiempo_trabajado.longitud_fin
          ),
          '_blank'
        )
      }
    }
  }
}
</script>
